
export default {
  props: ["total_like", "total_comment", "total_view", "id", "community_id"],
  props: {
    total_like: {
      type: Number,
    },
    total_comment: {
      type: Number,
    },
    total_view: {
      type: Number,
    },
    id: {
      type: Number,
    },
    community_id: {
      type: Number,
    },
    isListing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDialogOpen: false,
    };
  },
  methods: {
    share(option) {
      const enumSocMed = {
        facebook: "https://www.facebook.com/sharer/sharer.php?u=",
        whatsapp: "https://api.whatsapp.com/send?text=",
        twitter: "https://twitter.com/share?url=",
        linkedin: "https://www.linkedin.com/sharing/share-offsite/?url=",
      };
      const Link =
        this.link +
        (this.$store.state.auth.user.isLogin
          ? `?slug=${this.$store.state.auth.user.myProfile.slug}`
          : "");
      window.open(enumSocMed[option] + Link);
    },
    openDetail() {
      if (!this.$store.state.auth.user.isLogin) {
        this.isDialogOpen = true;
      } else {
        if (this.isListing)
          window.open(
            `${process.env.BASE_COMMUNITY}mbls/detail/${this.community_id}/${this.id}`,
            "_self"
          );
        else this.$goCommunity(`community/${this.community_id}/${this.id}`);
      }
    },
    handleModal(isConfirmed) {
      if (isConfirmed) {
        window.open(process.env.BASE_ACCOUNT + "/mb/login", "_self");
      } else {
        this.isDialogOpen = false;
      }
    },
    handleShare(val, item) {
      if (!window) {
        return;
      }
      if (!this.$store.state.auth.user.isLogin) {
        this.isDialogOpen = true;
        return;
      }
      const base = window.location.host;
      const dateNow = new Date().getTime();
      const username = this.$store.state.auth.user.myProfile.slug
        ? this.$store.state.auth.user.myProfile.slug
        : "_" + Math.floor(Math.random() * 10000 + 1);
      const url = this.isListing
        ? `https://${base}/mbls/detail/${this.community_id}/${this.id}?slug=${username}&_utm=${dateNow}`
        : `https://${base}/mb/community/${this.community_id}/${this.id}?slug=${username}&_utm=${dateNow}`;
      switch (val) {
        case "fb":
          return window.open(
            "http://www.facebook.com/sharer/sharer.php?u=" +
              url +
              "&_ch=facebook",
            "_blank"
          );
        case "wa":
          return window.open(
            "https://api.whatsapp.com/send?text=" + url + "&_ch=whatsapp",
            "_blank"
          );
        case "tw":
          return window.open(
            "https://twitter.com/share?url=" + url + "&_ch=twitter",
            "_blank"
          );
        case "in":
          return window.open(
            "http://www.linkedin.com/shareArticle?mini=true&url=" +
              url +
              "&_ch=linkedin",
            "_blank"
          );
      }
    },
  },
};
