
export default {
  data: () => ({
    isMouseDown: false,
    initialPos: 0,
    page: 0,
    pageX: 0,
  }),
  mounted() {
    const myRef = this.$refs.component;
    myRef.addEventListener("mousedown", (e) => {
      this.isMouseDown = true;
      this.initialPos = e.pageX;
    });
    myRef.addEventListener("mouseup", () => {
      this.isMouseDown = false;
      this.$refs.myChild.style.transform = `translateX(0px)`;
    });
    myRef.addEventListener("mouseout", () => {
      this.isMouseDown = false;
      this.$refs.myChild.style.transform = `translateX(0px)`;
    });
    myRef.addEventListener("mousemove", this.mouseMoveEventHandler);
  },
  methods: {
    mouseMoveEventHandler(e) {
      if (this.isMouseDown) {
        if (this.initialPos > e.pageX) {
          this.page = 0;
        } else {
          this.page = 1;
        }
        this.pageX = e.pageX;
        this.$refs.myChild.style.transform = `translateX(${
          e.pageX - this.initialPos
        }px)`;
      }
    },
    handleClick() {
      console.log(this.$refs.component);
    },
  },
};
