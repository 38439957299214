
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  components: {
    VueSlickCarousel: () => import("vue-slick-carousel"),
  },
  props: {
    samePage: {
      type: Boolean,
      default: true,
    },
    defaultIndex: { type: Number, default: null },
    tabData: {
      type: Array,
      default: () => [
        {
          name: "Item",
          onClick: {
            url: "/",
            sameDomain: true,
          },
        },
      ],
    },
    carouselSettings: {
      type: Object,
    },
  },
  data: () => ({
    activeTabIndex: 0,
    isMouseDown: false,
    initialPos: 0,
    filteredTab: [],
    tabObserver: [],
  }),
  watch: {
    tabData() {
      this.filterTabData();
      this.filteredTab = this.filteredTab.map((tab) => {
        return { ...tab, fullyVisible: true };
      });
    },
  },
  created() {
    if (this.defaultIndex && this.defaultIndex !== 0) {
      this.activeTabIndex = this.defaultIndex;
    }
  },
  async mounted() {
    await this.filterTabData();
    this.filteredTab = this.filteredTab.map((tab) => {
      return { ...tab, fullyVisible: true };
    });
    if (this.tabData.length) this.observe();
  },
  methods: {
    handleSelect(tabItem, index) {
      this.activeTabIndex = index;
      if (tabItem.onClick)
        if (tabItem.onClick.sameDomain) this.$router.push(tabItem.onClick.url);
        else window.open(tabItem.onClick.url, "_self");
      const prevItem = index - 2;
      if (!tabItem.fullyVisible) {
        this.$refs.carousel.goTo(prevItem);
      }
      this.$emit("changeTab", this.activeTabIndex);
    },
    filterTabData() {
      const filteredTab = this.tabData.filter((tab) => {
        const hasProperty = "isVisible" in tab;
        if (!hasProperty) return tab;
        if (tab.isVisible) return tab;
      });
      this.filteredTab = filteredTab;
    },
    observe() {
      // const slideElement = document.getElementsByClassName("slick-slide");
      const slideElement = document.getElementsByClassName("slick-slide");
      if (slideElement.length)
        slideElement.forEach((slide, i) => {
          const observer = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                  this.filteredTab[i].fullyVisible = false;
                } else this.filteredTab[i].fullyVisible = true;
              });
            },
            {
              threshold: 1,
            }
          );
          observer.observe(slide);
        });
    },
  },
};
