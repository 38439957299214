
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  components: {
    VueSlickCarousel: () => import("vue-slick-carousel"),
    questionGallery: () => import("@/components/organisms/gallery.vue"),
  },
  props: ["medias", "data", "communityData", "listingData", "isLogin"],
  data() {
    return {
      carouselSettings: {
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 3000,
        vertical: true,
        verticalSwiping: true,
        speed: 500,
      },
      // images: [
      //   {
      //     url: "https://image.modocapis.com/resize/450x0/kotak/2023030309/6401ba834fc1e_BMW_E30_M3.png",
      //   },
      //   {
      //     url: "https://image.modocapis.com/resize/450x0/kotak/2023030309/6401ba834fc1e_BMW_E30_M3.png",
      //   },
      //   {
      //     url: "https://image.modocapis.com/resize/450x0/kotak/2023030309/6401ba834fc1e_BMW_E30_M3.png",
      //   },
      //   {
      //     url: "https://image.modocapis.com/resize/450x0/kotak/2023032806/64228d8ddafc0_borobudur.jpg",
      //   },
      //   {
      //     url: "https://image.modocapis.com/resize/450x0/kotak/2023032806/64228d942e753_ss.jpg",
      //   },
      //   {
      //     url: "https://image.modocapis.com/resize/450x0/kotak/2023032808/6422aabdc897b_1.jpg",
      //   },
      // ],
    };
  },
  mounted() {},
  methods: {
    buyListingHandler() {
      window.open(this.listingData.url, "_blank");
    },
  },
};
